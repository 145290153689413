<template>
  <div style="height: 100%;">
    <div  class="scroll-container">
      <div class="head-search">
        <div class="head-right">
          <el-input class="input-with-select" ref="searchInput" placeholder="请输入知识名称" clearable
                    v-model="fileName">
            <template slot="prepend">
              <avue-input-tree :props="inputTreeProps" default-expand-all v-model="knowledgeValue" placeholder="请选择"
                               :dic="dic" @change="chooseKnowledge"></avue-input-tree>
            </template>
            <el-button slot="append" icon="el-icon-search" @click="selectKnowledge"
                       :style="{backgroundColor:themeColor}"
                       style="color: #FFFFFF"></el-button>
          </el-input>
        </div>
      </div>
      <div v-if="showClassification" class="tag-content">
        <knowledge-classification v-if="showClassification" ref="knowledgeClass"
                                  @select-tag="selectTag"></knowledge-classification>
      </div>
      <grid-layout ref="gridLayOut"
                   class="knowledgeCurd"
                   :table-options="tableOptions"
                   :table-data="tableData"
                   :table-loading="tableLoading"
                   :data-total="page.total"
                   :page="page"
                   @page-current-change="onLoad"
                   @page-size-change="onLoad"
                   @gird-handle-select-click="selectionKnowledge">
      </grid-layout>
    </div>
    <span slot="footer" class="dialogBtn">
      <el-button @click="closeKnowledgeDialog">取消</el-button>
      <el-button type="primary" @click="confirmKnowledge" :loading="confirmLoading">确认</el-button>
    </span>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import knowledgeClassification from "@/views/business/knowledge/components/knowledge-classification";
import {
  getKnowledgeClassifyTree, knowledgeSearchList,
  listBrowse,
  removeBrowse,
  search,
} from "@/api/knowledgeClassifcation/knowledge";
import {mapGetters} from "vuex";

export default {
  props: {
    dialogType: {
      type: String,
      default: ""
    },
    selectable: {
      type: Function,
      default: () => {
        return true
      },
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      knowledgeType: '',
      tableData: [],
      showHistory: false,
      browseList: [], //浏览历史
      browseListAll: [],
      browsePage: {
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      fileName: "",
      fileTypeId: "",
      element: [],
      searchList: [],
      showSearchList: false,
      knowledgeValue: 'all',
      inputTreeProps: {
        label: "classifyName",
        value: "id",
      },
      dic: [],
      showClassification: false,
      cardWidthType: false,
      selectionKnowledgeList: [],
    };
  },

  watch: {
    themeColor(val) {
      this.themeColor = val;
    },
  },
  components: {
    knowledgeClassification,
    GridLayout,
  },

  computed: {
    ...mapGetters(["themeColor"]),
    ids() {
      let ids = [];
      this.browseListAll.forEach((ele) => {
        ids.push(ele.browseId);
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selection: true,
        menu: false,
        reserveSelection: true,
        selectable: this.selectable,
        indexWidth: 80,
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "收藏/浏览",
            align: "center",
            width: 170,
            prop: "collectCount",
            formatter: (row) => {
              return row.collectCount + "/" + row.browseCount;
            },
          },
          {
            label: "文档类型",
            align: "center",
            width: 120,
            prop: "fileSuffixType",
          },
          {
            label: "上传时间",
            align: "center",
            width: 220,
            prop: "updateTime",
          },
        ],
      };
    },
  },

  mounted() {
    this.initTree();
    this.onLoad(this.page);
    window.addEventListener('resize', this.handleResize);
    if (window.innerWidth <= 1200) {
      this.cardWidthType = true;
    }
  },
  methods: {
    // 弹框确定方法
    confirmKnowledge() {
      if(this.selectionKnowledgeList.length == 0){
        this.$message.warning('请先选择数据')
      } else {
        this.$emit("select-data", this.selectionKnowledgeList);
        this.closeKnowledgeDialog();
      }
    },
    closeKnowledgeDialog() {
      this.$refs.gridLayOut.grid.toggleSelection()
      this.$emit("close-dialog");
    },
    selectionKnowledge(list) {
      this.selectionKnowledgeList = list;
    },
    selectKnowledge() {
      this.onLoad(this.page);
    },

    handleResize() {
      if (window.innerWidth <= 1200) {
        this.cardWidthType = true;
      } else {
        this.cardWidthType = false;
      }
    },
    initTree() {
      getKnowledgeClassifyTree()
        .then((result) => {
          this.dic = result.data.data;
          this.dic.unshift({id: 'all', classifyName: '全部'})
        })
    },
    chooseKnowledge(e) {
      if (e) {
        this.showClassification = true;
        this.$nextTick(() => {
          this.$refs.knowledgeClass.showList = [];
          this.$refs.knowledgeClass.selectTags = [];
          if (e != 'all') {
            this.knowledgeType = e
          } else {
            this.knowledgeType = ''
          }
          this.selectTag(this.$refs.knowledgeClass.selectTags);
          if (e == 'all') {
            this.showClassification = false;
            this.onLoad(this.page);
          } else {
            this.$refs.knowledgeClass.getList(e);
          }
        })
      }
    },
    onLoad(page) {
      this.showHistory = false;
      this.page = page;
      this.tableLoading = true;
      knowledgeSearchList(
        page.currentPage,
        page.pageSize,
        {
          documentType: this.dialogType,
          knowledgeType: this.knowledgeType,
          fileName: this.fileName,
          fileTypeId: this.fileTypeId
        }
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        if (this.$refs.gridLayOut) {
          this.$refs.gridLayOut.page.total = data.total;
        }
        this.tableData = data.list;
        this.$forceUpdate();
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    selectTag(list) {
      let fileTypeId = [];
      if (list.length > 0) {
        list.forEach((item) => {
          fileTypeId.push(item.id);
        });
      }
      this.fileTypeId = fileTypeId.toString();
      this.onLoad(this.page);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    for (let index = 0; index < this.element; index++) {
      this.$videos(`myVideos${index}`).dispose();
    }
  },
};
</script>

<style scoped lang='scss'>

.scroll-container {
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;

  /* 隐藏默认的滚动条样式 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.dialogBtn {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}

.head-search {
  position: relative;

  .head-right {
    position: relative;
    left: 1%;
    padding-bottom: 5px;

    .el-input {
      width: 300px;
    }
  }
}

.tag-content {
  padding: 5px 12px;
  background: #fff;
}

.el-pagination {
  text-align: right;
}

::v-deep .el-input-group__prepend .el-input {
  width: 100px !important;
}

::v-deep .el-input-group__prepend .el-input .el-input__inner {
  width: 100px !important;
}

::v-deep .input-with-select .el-input__inner {
  width: 240px;
  margin: 0;
}

::v-deep .knowledgeCurd .avue-crud .el-table {
  height: calc(100vh - 357px) !important;
  max-height: calc(100vh - 357px) !important;
}
</style>
