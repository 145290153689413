var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      { staticClass: "scroll-container" },
      [
        _c("div", { staticClass: "head-search" }, [
          _c(
            "div",
            { staticClass: "head-right" },
            [
              _c(
                "el-input",
                {
                  ref: "searchInput",
                  staticClass: "input-with-select",
                  attrs: { placeholder: "请输入知识名称", clearable: "" },
                  model: {
                    value: _vm.fileName,
                    callback: function ($$v) {
                      _vm.fileName = $$v
                    },
                    expression: "fileName",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "prepend" },
                    [
                      _c("avue-input-tree", {
                        attrs: {
                          props: _vm.inputTreeProps,
                          "default-expand-all": "",
                          placeholder: "请选择",
                          dic: _vm.dic,
                        },
                        on: { change: _vm.chooseKnowledge },
                        model: {
                          value: _vm.knowledgeValue,
                          callback: function ($$v) {
                            _vm.knowledgeValue = $$v
                          },
                          expression: "knowledgeValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-button", {
                    staticStyle: { color: "#FFFFFF" },
                    style: { backgroundColor: _vm.themeColor },
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.selectKnowledge },
                    slot: "append",
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _vm.showClassification
          ? _c(
              "div",
              { staticClass: "tag-content" },
              [
                _vm.showClassification
                  ? _c("knowledge-classification", {
                      ref: "knowledgeClass",
                      on: { "select-tag": _vm.selectTag },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c("grid-layout", {
          ref: "gridLayOut",
          staticClass: "knowledgeCurd",
          attrs: {
            "table-options": _vm.tableOptions,
            "table-data": _vm.tableData,
            "table-loading": _vm.tableLoading,
            "data-total": _vm.page.total,
            page: _vm.page,
          },
          on: {
            "page-current-change": _vm.onLoad,
            "page-size-change": _vm.onLoad,
            "gird-handle-select-click": _vm.selectionKnowledge,
          },
        }),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "dialogBtn", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c("el-button", { on: { click: _vm.closeKnowledgeDialog } }, [
          _vm._v("取消"),
        ]),
        _c(
          "el-button",
          {
            attrs: { type: "primary", loading: _vm.confirmLoading },
            on: { click: _vm.confirmKnowledge },
          },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }